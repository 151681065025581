import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
      secondary: {
        main: '#fff',
      },
    },
    // arcada-styling
    // typography: {
    //   "fontFamily": `"futura-pt", sans-serif;`
    //  }
//      .futura-pt { font-family: "futura-pt",sans-serif; }
// .futura-pt-bold { font-family: "futura-pt-bold",sans-serif; }
// .futura-pt-book { font-family: "futura-pt-book",sans-serif; }
});

export default theme;