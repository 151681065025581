import React from 'react'
import { css } from '@emotion/react'
import graphic from "../images/header-vector-2.svg"
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from "react-helmet"
//import arcadaLogo from "../images/arcada-logo.svg"
import techlabsLogo from "../images/logo/logo.svg"

import {
  Button,
  IconButton,
} from "gatsby-theme-material-ui";

import Drawer from '@mui/material/Drawer';
import useWindowWidth from '../utils/useWindowWidth';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ data }) => {
  const windowWidth = useWindowWidth()
  const [drawerState, setDrawerState] = React.useState(false)

  const Menu = ({ color, direction }) => (
    <div style={{ display: "flex", flexDirection: direction, justifyContent: "space-around" }}>
      <div css={css`
        @media only screen and (max-width: 921px) {
          width: min-content; margin: 0 auto;
        }
      `}>
        <IconButton to="/search" color={color} aria-label="search"  >
          <SearchIcon color={color} />
        </IconButton>
      </div>

      <Button size="large" color={color} to="/people" onClick={() => setDrawerState(false)}>People</Button>
      <Button size="large" color={color} to="/posts" onClick={() => setDrawerState(false)}>Posts</Button>
      <Button size="large" color={color} to="/labs" onClick={() => setDrawerState(false)}>Labs</Button>
      <Button size="large" color={color} to="/projects" onClick={() => setDrawerState(false)}>Projects</Button>
      <Button size="large" color={color} to="/publications" onClick={() => setDrawerState(false)}>Publications</Button>
    </div>
  )

  return (
    <div css={css`height: 10rem`}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <a href="#main" css={css`
        left: 50%;
        position: absolute;
        transform: translateY(-100%);
        background-color: #fff;
        padding: 0.5rem 1rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        text-decoration: none;
        font-weight: bold;
        color: #004DBE; 
        &:focus {
          transform: translateY(0%);
        }
      `}>Skip to content</a>
      <img
        css={css`
          width:100%; 
          height: 10rem; 
          position: absolute; 
          left: 0; 
          top: 0; 
          z-index: -1;
        `}
        alt=""
        src={graphic}
      />

      {/* arcada-styling */}
      {/* <div css={css`display: flex; flex-direction: row;   position: absolute;
          top: 1rem;
          left: 4rem;`}>
        <p css={css`font-family: "futura-pt",sans-serif; font-size: 1.2rem; text-transform: uppercase; line-height: 1.4rem;`}>Arcada <br />Tech Labs </p>
        <img src={arcadaLogo} alt="" css={css`width: 4rem; margin-left: 4rem`} />
      </div> */}

      {/* <div
        css={css`
          position: absolute;
          top: 14px;
          left: 28px;
          font-family: Roboto;
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: black;
        `}
      >
        <div >Tech</div>
        <div
          css={css`
            position: absolute;
            top: 17.8px;
            left: 13.3px;
            letter-spacing: -5% !important;
            `}
        >Labs</div>
      </div> */}
      <a href='/'>
        <img src={techlabsLogo} alt="" css={css`width: 6.5rem; margin-left: 1.5rem; margin-top: 1.4rem;`} />
      </a>

  
      {windowWidth < 920 ?

        <div>
          <IconButton
            css={css`
            position: absolute; 
            right: 1rem; 
            top: 0.5rem`
            }
            color={"secondary"}
            onClick={() => setDrawerState(!drawerState)}>
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerState}
            onClose={() => setDrawerState(!drawerState)}
          >
            <div css={css`width: 20rem; margin-top: 4rem`}><Menu color="primary" direction="column" /></div>
          </Drawer>
        </div>

        :

        <div
          css={
            css`
            position: absolute;
            top: 17.8px;
            right: 0;
            width: 50rem;
            `}>
          <Menu color="secondary" direction="row" />
        </div>
      }
    </div>
  )
}

export default Header
