// gatsby-browser.js
import "./src/css/code-snippet.css"
import "./src/css/fadein.css"
import "./src/css/scrollbar.css"
import "./src/css/tables.css"
import "./src/fonts/fonts.css"
import CustomLayout from "./wrapPageElement"

// styles for code high lighting
require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("prismjs/plugins/command-line/prism-command-line.css")

export const wrapPageElement = CustomLayout